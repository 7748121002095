export const BaseUrl =
  process.env.VUE_APP_BASE_URL || "https://www.be.zerogames.org";

export const USER = "/user";
export const ACCOUNT = "/account";

export const GET_USER = `${USER}/get_user`;
export const SECONDARY_LOGIN = `${USER}/secondary_login`;
export const EMAIL_VERIFICATION = `${USER}/email_verification`;
export const SET_PRIMARY = `${USER}/set_primary`;
export const DELETE_USER = `${USER}/delete_sub_user`;
export const STATUS = `${USER}/update_status`;
export const EMAIL = `${USER}/emails`;
export const RETRY_EMAIL = `${USER}/retry_email`;
export const PLATFORMS = `${USER}/platforms`;
export const CREATE_USER = `${USER}/signup`;
export const LOGIN_USER = `${USER}/login`;

export const LOGIN_ACCOUNT = `${ACCOUNT}/login`;

export const PLATFORM = "/platform";
export const CREATE_PLATFORM = `${PLATFORM}/create`;
export const DELETE_PLATFORM = `${PLATFORM}/`;
